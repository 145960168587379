const menu = [
  {
    header: 'Central de informações',
    profile: ['super_admin', 'user'],
  },
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'HomeIcon',
    profile: ['super_admin', 'admin', 'user'],
  },
  {
    title: 'Gráficos',
    route: 'graphs',
    icon: 'TrendingUpIcon',
    profile: ['super_admin', 'admin', 'user'],
  },
  {
    title: 'Mapas',
    route: 'maps',
    icon: 'MapIcon',
    profile: ['super_admin', 'admin', 'user'],
  },
  {
    title: 'Listagens',
    route: 'tables',
    icon: 'ListIcon',
    profile: ['super_admin', 'admin', 'user'],
  },
  {
    header: 'Cadastros',
    profile: ['super_admin', 'admin'],
  },
  {
    title: 'Dispositivos',
    route: 'devices.index',
    icon: 'MonitorIcon',
    profile: ['super_admin', 'admin', 'user'],
  },
  {
    header: 'Adminstração do Sistema',
    profile: ['super_admin', 'admin'],
  },
  {
    title: 'Usuários',
    route: 'usuarios',
    icon: 'UserIcon',
    profile: ['super_admin', 'admin'],
  },
  {
    title: 'Clientes',
    route: 'clients.list',
    faicon: 'far fa-building',
    profile: ['super_admin', 'admin'],
  },
  {
    title: 'Configurações',
    route: 'settings.whatsapp',
    faicon: 'fa-brands fa-whatsapp',
    profile: ['super_admin', 'admin'],
  },
]

export default menu
